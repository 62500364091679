
import { defineComponent, ref, toRefs } from "vue";
import Multiselect from "@vueform/multiselect";
import CategoryResource from "@/resources/CategoryResource";
import Gallery from "@/components/Product/Gallery.vue";
import Upload from "@/components/Product/Gallery/Upload.vue";

export default defineComponent({
  components: { Upload, Gallery, Multiselect },
  props: ["form"],

  setup(props) {
    const categoryOptions = ref([]);
    const { model, errors } = toRefs(props.form.state);

    CategoryResource.query().then((response) => {
      categoryOptions.value = response.data.data;
    });

    return {
      categoryOptions,
      model,
      errors,
    };
  },
});
