
import {defineComponent, onMounted, ref, Ref} from 'vue';
import Dropzone, { DropzoneFile } from 'dropzone';
import useApiToken from '@/composables/use-api-token';
import { find } from 'lodash';

export interface IUploadResponse {
    fileName: string;
    filePath: string;
    originalName: string;
}

export type UploadedFile = {
    uuid?: string;
    fileName: string;
    filePath: string;
    originalName: string;
};

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        modelValue: Array,
    },
    setup(props, { emit }) {
        const { getApiToken } = useApiToken();
        const uploadedFiles: Ref<UploadedFile[]> = ref([]);

        onMounted(() => {
            Dropzone.autoDiscover = false;
            const uploader = new Dropzone('div#dropzone', {
                url: `${process.env.VUE_APP_API_URL}/upload`,
                headers: {
                    Authorization: `Bearer ${getApiToken()}`,
                },
                addRemoveLinks: true,
            });

            uploader.on('success', (event: DropzoneFile, response: IUploadResponse) => {
                uploadedFiles.value.push({
                    uuid: event.upload?.uuid,
                    ...response,
                });

                emit('update:modelValue', uploadedFiles);
            });

            uploader.on('removedfile', (event: DropzoneFile) => {
                const uploadedFile = find(
                    uploadedFiles.value,
                    (file: UploadedFile) => {
                        return file.uuid === event.upload?.uuid;
                    }
                );

                if (uploadedFile) {
                    uploadedFiles.value.splice(
                        uploadedFiles.value.indexOf(uploadedFile),
                        1
                    );
                }

                emit('update:modelValue', uploadedFiles);
            });
        });
    },
});
