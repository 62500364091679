import ApiResource, { IModel } from '@/resources/ApiResource';

class MediaResource extends ApiResource {}

export default new MediaResource('/media');

export interface IProductMediaModel extends IModel {
    id: number;
    file_name: string;
    url: string;
}
