
import { defineComponent, computed, Ref, ref } from 'vue';
import i18n from '@/i18n';
import * as basicLightbox from 'basiclightbox';
import MediaResource from '@/resources/MediaResource';
const { t } = i18n.global;

type MediaImage = {
    id: number,
    file_name: string,
    url: string
}

export default defineComponent({
    props: ['media'],
    setup(props) {
        const deletedImages: Ref<number[]> = ref([]);
        const images = computed(() => {
            return Array.from(props.media).filter((image: any) => {
                return !deletedImages.value.includes(image.id);
            });
        });

        function deleteImage(image: MediaImage) {
            if (confirm(t('general.CONFIRM_DELETE'))) {
                MediaResource.delete(image).then(() => {
                    deletedImages.value.push(image.id);
                });
            }
        }

        function openFull(image: MediaImage): void {
            basicLightbox.create(`<img src="${image.url}" />`).show();
        }

        return {
            images,
            deleteImage,
            openFull,
        }
    }
});
