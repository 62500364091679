import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "columns is-multiline" }
const _hoisted_2 = { class: "has-text-right" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "image is-64x64" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "column is-one-quarter",
        key: image.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            class: "delete is-medium",
            onClick: _withModifiers(($event: any) => (_ctx.deleteImage(image)), ["prevent"])
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("figure", _hoisted_4, [
          _createElementVNode("a", {
            onClick: _withModifiers(($event: any) => (_ctx.openFull(image)), ["prevent"])
          }, [
            _createElementVNode("img", {
              src: image.conversions['category-thumb'].url,
              alt: ""
            }, null, 8, _hoisted_6)
          ], 8, _hoisted_5)
        ])
      ]))
    }), 128))
  ]))
}